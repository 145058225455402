import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import _ from 'underscore';

import CollapsibleCard from './CollapsibleCard';

import config from "./../config";
import helper from './../helper'

function Ritregla(props) {
	const [visible, setVisible] = useState(false);

	const formatRitrLink = (s) => {
		return s.indexOf('.') == -1 && s.length < 3 ? s+'.' : s;
	};

	return <div className={'ritregla'+(visible ? ' visible' : '')}>Sjá <a title={props.ritregla.ritr2015_heiti_reglu}
		href={'//ritreglur.arnastofnun.is/#'+formatRitrLink(props.ritregla.ritr2015_nr_reglu)}
		target="_blank"
		onClick={(event) => {
			event.preventDefault();

			setVisible(!visible);
		}}
	>§ {props.ritregla.ritr2015_nr_reglu}</a> í Ritreglum
		<div className="content-frame">
			<div className="frame-heading">Ritreglur - <a target="_blank" href={'//ritreglur.arnastofnun.is/#'+formatRitrLink(props.ritregla.ritr2015_nr_reglu)}>https://ritreglur.arnastofnun.is/#{formatRitrLink(props.ritregla.ritr2015_nr_reglu)}</a></div>
			<iframe width="100%" height="300" src={'//ritreglur.arnastofnun.is/?nomenu#'+formatRitrLink(props.ritregla.ritr2015_nr_reglu)}/>
		</div>
	</div>
}


class FlettaView extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/flettur/';

		this.state = {};
	}

	componentDidMount() {
		if (this.props.match && this.props.match.params.fletta_id) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.match && this.props.match.params.fletta_id && prevProps.match.params.fletta_id != this.props.match.params.fletta_id) {
			this.fetchData();
		}
	}

	fetchData() {
		fetch(this.url+this.props.match.params.fletta_id)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					data: json
				})
			}.bind(this));
	}

	render() {
		let dataItem = this.state.data || this.props.data || null;

		if (!dataItem) {
			return <></>;
		}

		let inlinesComma = [];
		let inlines = [];

		if (dataItem.greinir) {
			inlinesComma.push(dataItem.greinir);
		}
		if (dataItem.kennimyndir) {
			inlinesComma.push(dataItem.kennimyndir.split('|').join(''));
		}
		if (dataItem.daemi) {
			inlines.push(<em className="color-blue">{dataItem.daemi.split('|').join('·')}</em>);
		}
		if (dataItem.stigbreyting || dataItem.stigbreyting_annad) {
			inlinesComma.push(<>STIGB {dataItem.stigbreyting}{dataItem.stigbreyting && dataItem.stigbreyting_annad ? ', ' : ' '}{dataItem.stigbreyting_annad}</>);
		}
		if (dataItem.nafntegund) {
			inlines.push(<em>({dataItem.nafntegund})</em>);
		}

		if (dataItem.efnisflokkar && dataItem.efnisflokkar.length > 0) {
			dataItem.efnisflokkar.forEach((efnisflokkur, index) => inlines.push(<em>({efnisflokkur.notkunarsvid})</em>));
		}

		//if (dataItem.latneskt_heiti) {
		//	inlines.push(<em>({dataItem.latneskt_heiti})</em>);
		//}

		let millivisanirEinnig = _.where(dataItem.millivisanir, {millivisun_type: 'Einnig'});
		let millivisanirAnnad = _.filter(dataItem.millivisanir, function(item) {
			return item.millivisun_type != 'Einnig'
		});

		let hasVisun = _.filter(dataItem.millivisanir, function(item) {
			return item.millivisun_type == 'Sjá'
		}).length > 0;

		let flettuBirting = dataItem.fletta;

		if (dataItem.ordskipting) {
			let ordskipting = JSON.parse(dataItem.ordskipting);

			let parts = ordskipting.map((o, i) => {
				return i == 0 ? flettuBirting.slice(0, o) : flettuBirting.slice(ordskipting[i-1], o)
			});
			parts.push(flettuBirting.slice(ordskipting[ordskipting.length-1]));

			flettuBirting = parts.join('<span class="ohlut-separator">|</span>')
		}

		if (hasVisun) {
			//flettuBirting = '<del>'+flettuBirting+'</del>';
			flettuBirting = flettuBirting;
		}

		return (
			<React.Fragment>
				{
					dataItem &&
					<div className={this.state.data ? 'card' : ''}>
						<div className={this.state.data ? 'card-body' : ''}>

							<p><span className={!hasVisun ? 'lead' : ''} dangerouslySetInnerHTML={{__html: flettuBirting}} />
								{
									dataItem.nr && <> {dataItem.nr}</>
								}
								{
									dataItem.ordflokkur && <span className={'color-blue'}> {helper.ordflokkur(dataItem.ordflokkur)}</span>
								}
								{
									dataItem.merking &&
									<span data-field="merking"> '{dataItem.merking}'</span>
								}
								{
									(
										dataItem.nr ||
										dataItem.ordflokkur ||
										dataItem.merking ||
										dataItem.l_framburdur == 'True' ||
										millivisanirAnnad.length > 0 ||
										millivisanirEinnig.length > 0
									) && !hasVisun &&
									<br/>
								}
								{
									dataItem.l_framburdur == 'True' &&
									<span data-field="l_framburdur">[-<em>l:{dataItem.fletta.slice(-2) == 'll' ? '' : '-'}</em>] </span>
								}
								{
									inlinesComma.map((inline, index) => {
										return <span data-field="inlinesComma" key={index}>{index > 0 ? ', ' : ' '}{inline}</span>;
									})
								}
								{
									inlines.map((inline, index) => {
										return <span data-field="inlines" key={index}>{' '}{inline}</span>;
									})
								}
								{
									millivisanirAnnad.length > 0 &&
									<span data-field="millivisanirAnnad">{
										millivisanirAnnad.map((visun) => <> {visun.millivisun_type == 'Skylt' ? '(skylt ' : visun.millivisun_type == 'Einnig' ? '(einnig ritað ' : <span className="visun-arrow">→ </span>}<Link to={'/fletta/'+visun.flid}>{visun.fletta}</Link>{visun.millivisun_type != 'Sjá' ? ')' : ''}</>)
									}</span>
								}
								{
									millivisanirEinnig.length > 0 &&
									<span data-field="millivisanirEinnig"> (einnig ritað {
										millivisanirEinnig.map((visun, index) => <>{index > 0 ? (index == millivisanirEinnig.length-1 ? ' og ' : ', ') : ''}<Link to={'/fletta/'+visun.flid}>{visun.fletta}</Link></>)
									})</span>
								}
								{
									dataItem.ritreglur && dataItem.ritreglur.length > 0 && <>
										<br/>
										{
											dataItem.ritreglur.map((ritregla) => <Ritregla ritregla={ritregla} />)
											//dataItem.ritreglur.map((ritregla) => <>(sjá <a title={ritregla.ritr2015_heiti_reglu} href={'http://ritreglur.arnastofnun.is/#'+ritregla.ritr2015_nr_reglu} target="_blank">§ {ritregla.ritr2015_nr_reglu}</a> í Ritreglum) </>)
										}
									</>
								}
							</p>

							{
								/*dataItem.efnisflokkar && dataItem.efnisflokkar.length > 0 && <div className="mt-2">
									{
										dataItem.efnisflokkar.map((efnisflokkur, index) => <>{index > 0 ? ', ' : ''}<span class="badge badge-light">{efnisflokkur.notkunarsvid}</span></>)
									}
								</div>*/
							}

							{
								this.props.data && !dataItem.pistill &&
								<Link className="fletta-link" to={'/fletta/'+dataItem.id}>
									<span className="badge badge-light">Tengill á flettu</span>
								</Link>
							}

						</div>
					</div>
				}
			</React.Fragment>
		);
	}
}

export default FlettaView;
