import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";

import config from './../config';
import FlettaView from './FlettaView';

class SearchResultsList extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/flettur/';

		this.prevPageClickHandler = this.prevPageClickHandler.bind(this);
		this.nextPageClickHandler = this.nextPageClickHandler.bind(this);

		this.state = {
			listData: [],
			loading: false
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			prevProps.match.params.searchString != this.props.match.params.searchString ||
			prevProps.match.params.flokkur != this.props.match.params.flokkur ||
			prevProps.match.params.undirflokkur != this.props.match.params.undirflokkur ||
			prevProps.match.params.page != this.props.match.params.page
		) {
			this.fetchData();
		}
	}

	fetchData(url) {
		window.scrollTo(0, 0);

		this.setState({
			searchString: this.props.match.params.searchString,
			flokkur: this.props.match.params.flokkur,
			undirflokkur: this.props.match.params.undirflokkur,
			loading: true
		});

		let urlParams = [];
		if (this.props.match.params.searchString) {
			urlParams.push('fletta='+this.props.match.params.searchString);
		}
		if (this.props.match.params.page) {
			urlParams.push('offset='+((this.props.match.params.page-1)*config.pageSize));
		}

		fetch(url || this.url+'?'+urlParams.join('&'))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.results,
					resultsCount: json.count,
					prevUrl: json.previous || null,
					nextUrl: json.next || null,
					loading: false
				})
			}.bind(this));

		if (this.props.onSearch) {
			this.props.onSearch(this.props.match.params);
		}
	}

	prevPageClickHandler(event) {
		event.preventDefault();

		this.fetchData(this.state.prevUrl);
	}

	nextPageClickHandler(event) {
		event.preventDefault();

		this.fetchData(this.state.nextUrl);
	}

	render() {
		let paginationUrl = '/flettur/'+
			(this.props.match.params.searchString ? this.props.match.params.searchString : '')+
			(this.props.match.params.flokkur ? '/flokkur/'+this.props.match.params.flokkur : '')+
			(this.props.match.params.undirflokkur ? '/undirflokkur/'+this.props.match.params.undirflokkur : '')
		;

		let pageButtons = [];

		let maxButtonCount = 7;

		let startPage = parseInt(this.props.match.params.page || 1);

		if (startPage > Math.floor(maxButtonCount/2) && this.state.resultsCount/100 > maxButtonCount) {
			startPage = startPage-(Math.floor(maxButtonCount/2));
		}
		else {
			startPage = 1;
		}

		let maxPageCount = Math.ceil((this.state.resultsCount/100))+1;

		maxPageCount = maxPageCount > startPage+maxButtonCount ? startPage+maxButtonCount : maxPageCount;
		maxPageCount = maxPageCount > this.state.resultsCount/100 ? Math.ceil(this.state.resultsCount/100) : maxPageCount;

		if (startPage > 1) {
			pageButtons.push(
				<div key="1" className="page-item">
					<NavLink className="page-link btn-info" to={paginationUrl+'/sida/1'} aria-label="Previous">
						<span aria-hidden="true">1</span>
					</NavLink>
				</div>
			);

			if (startPage > 2) {
				pageButtons.push(
					<div key="..." className="page-item">
						<div className="page-link btn-info">
							<span aria-hidden="true">...</span>
						</div>
					</div>
				);
			}
		}

		for (let i = startPage; i<maxPageCount; i++) {
			pageButtons.push(
				<div key={i} className="page-item">
					<NavLink className="page-link btn-info" to={paginationUrl+'/sida/'+(i)} aria-label="Previous">
						<span aria-hidden="true">{i}</span>
					</NavLink>
				</div>
			);
		}

		if (Math.ceil(this.state.resultsCount/100) > maxPageCount) {
			pageButtons.push(
				<div key="...." className="page-item">
					<div className="page-link btn-info">
						<span aria-hidden="true">...</span>
					</div>
				</div>
			);
		}

		pageButtons.push(
			<div key={Math.ceil((this.state.resultsCount/100))} className="page-item">
				<NavLink className="page-link btn-info" to={paginationUrl+'/sida/'+Math.ceil((this.state.resultsCount/100))} aria-label="Previous">
					<span aria-hidden="true">{Math.ceil((this.state.resultsCount/100))}</span>
				</NavLink>
			</div>
		);

		let paginationLinks = <React.Fragment>
			{
				this.state.prevUrl &&
				<div className="page-item">
					<Link className="page-link btn-info" to={paginationUrl+(this.props.match.params.page ? '/sida/'+(parseInt(this.props.match.params.page)-1) : '')} aria-label="Previous">
						<span aria-hidden="true"><span className="arrows">&laquo;</span> Fyrri síða</span>
						<span className="sr-only">Fyrri síða</span>
					</Link>
				</div>
			}
			{
				pageButtons
			}
			{
				this.state.nextUrl &&
				<div className="page-item">
					<Link className="page-link btn-info" to={paginationUrl+(this.props.match.params.page ? '/sida/'+(parseInt(this.props.match.params.page)+1) : '/sida/2')} aria-label="Next">
						<span aria-hidden="true">Næsta síða <span className="arrows">&raquo;</span></span>
						<span className="sr-only">Næsta síða</span>
					</Link>
				</div>
			}
		</React.Fragment>;

		let hasRenderedPistill = false;

		let categoryName;
		if (this.props.match.params.flokkur && this.state.listData.length > 0) {
			categoryName = this.state.listData[0].flokkar[0].yfirflokkur.nafn;

			if (this.state.listData[0].flokkar[0].nafn != '-') {
				categoryName += ', '+this.state.listData[0].flokkar[0].nafn;
			}
		}

		return (
			<div>
				<div>{this.props.match.searchString}</div>

				{
					this.state.listData.length > 0 && (this.state.prevUrl || this.state.nextUrl) &&
					<div className="pagination mb-2">
						{
							paginationLinks
						}
					</div>
				}

				<ul className="list-group mb-4 dictionary-list">
					{
						this.state.listData.length > 0 ?
						this.state.listData.map(function(item, index) {
							let extraClass = '';

							// Hér birtum við millifyrirsögnina "Pistlar" fyrir ofan
							// fyrsta pistilinn ef um blandaðar niðurstöður er að ræða
							let displayPistillHeader = false;

							if (index == 0 && item.pistill) {
								hasRenderedPistill = true;
								displayPistillHeader = false;
							}
							else if (!hasRenderedPistill && item.pistill && index > 0) {
								extraClass = ' mt-2';
								hasRenderedPistill = true;
								displayPistillHeader = true;
							}

							return <React.Fragment key={index}>
								{
									displayPistillHeader && <div className="mt-4"><strong>Pistlar</strong></div>
								}
								<li key={index} className={'list-group-item pt-3 pb-3'+extraClass+(item.pistill ? ' item-pistill' : '')}>
									<FlettaView data={item} />
								</li>
							</React.Fragment>;
						})
						:
						this.state.loading ?
						<li className="list-group-item" />
						:
						<li className="list-group-item">
							{this.props.match.params.searchString ? 'Leitarorðið „'+this.props.match.params.searchString+'“ skilaði engum niðurstöðum.' : 'Ekkert fannst'}
						</li>
					}
				</ul>

				{
					this.state.listData.length > 0 &&
					<div className="pagination">
						{
							(this.state.prevUrl || this.state.nextUrl) &&
							paginationLinks
						}
					</div>
				}

			</div>
		);
	}
}

export default SearchResultsList;
