export default {
	pageSize: 100,

	//apiRoot: 'http://130.208.108.18:8000',
	apiRoot: '//stafsetning.arnastofnun.is/django',

	vefurRoot: '//stafsetning.arnastofnun.is/django/vefur',

	siteTitle: 'Íslensk stafsetningarorðabók',

	gaTrackingId: 'G-RNKBBE8JRK'
}
