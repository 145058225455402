import React, { Component } from 'react';
import { Link } from "react-router-dom";

import config from './../config';

import _ from 'underscore';

class IndexList extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/flettulisti/';

		this.windowResizeHandler = this.windowResizeHandler.bind(this);

		this.state = {
			listData: [],
			fetching: true
		};
	}

	componentDidMount() {
		if (this.props.flid || this.props.fletta) {
			this.fetchData();
		}

		this.mobileWidth = window.innerWidth < 550;

		window.addEventListener('resize', this.windowResizeHandler);
	}

	componentDidUpdate(prevProps, prevState) {
		// Skoðað hvort breytur hafa breyst og hvort component eigi að uppfærast
		if (
			prevProps.flid != this.props.flid ||
			prevProps.fletta != this.props.fletta
		) {
			this.fetchData();
		}

		window.removeEventListener('resize', this.windowResizeHandler);
	}

	windowResizeHandler(event) {
		console.log('resize')
		let mobileWidth = window.innerWidth < 550;

		if (mobileWidth != this.mobileWidth) {
			this.mobileWidth = mobileWidth;

			this.forceUpdate();
		}
	}

	fetchData() {
		// Sæki gögn til APA
		this.setState({
			listData: [],
			fetching: true,
		});

		let urlParams = [];

		if (this.props.flid) {
			urlParams.push('flid='+this.props.flid);
		}
		if (this.props.fletta) {
			urlParams.push('fletta='+this.props.fletta);
		}

		if (window.innerWidth < 550) {
			urlParams.push('before=1');
			urlParams.push('after=5');
		}

		fetch(this.url+'?'+urlParams.join('&'))
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.results,
					fetching: false
				});
			}.bind(this));
	}

	render() {
		let currentLang = window.currentLang || 'is';

		let foundActiveItem = false;

		return (
			<React.Fragment>
				{
					this.state.listData.length > 0 &&
					<React.Fragment>
						<ul className="nav nav-pills flex-column">
							{
								this.state.listData.map(function(item, index) {
									let sjaVisun;

									if (item.millivisanir && item.millivisanir.length > 0) {
										sjaVisun = _.findWhere(item.millivisanir, {millivisun_type: 'Sjá'});
									}

									let extraClass = '';
									if (((this.props.flid && item.id == this.props.flid) || (this.props.fletta && item.fletta == this.props.fletta)) && !foundActiveItem) {
										extraClass = ' active';
										foundActiveItem = true;
									}

									return <li key={index} className={'nav-item'}>
										{
											!sjaVisun &&
											<Link className={'nav-link pt-1 pb-1'+extraClass} key={index} to={'/fletta/'+item.id}>{item.rn ? item.rn+' ' : ''}{item.fletta}</Link>
										}
										{
											sjaVisun &&
											<Link className={'nav-link pt-1 pb-1'+extraClass} key={index} to={'/fletta/'+sjaVisun.flid}>{item.rn ? item.rn+' ' : ''} {item.fletta} → {sjaVisun.fletta}</Link>
										}
									</li>;
								}.bind(this))
							}
						</ul>
					</React.Fragment>
				}
			</React.Fragment>
		);
	}
}

export default IndexList;
