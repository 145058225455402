import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import SearchBox from './components/SearchBox';
import SearchResultsList from './components/SearchResultsList';
import FlettaView from './components/FlettaView';
import FlokkarList from './components/FlokkarList';

import CollapsibleCard from './components/CollapsibleCard';
import StickyWatcher from './components/StickyWatcher';
import IndexList from './components/IndexList';

import FrontPage from './components/FrontPage';
import PageMenu from './components/PageMenu';
import PageView from './components/PageView';
import GoogleAnalytics from './components/GoogleAnalytics';

import logo from './img/logo-single.png';

class App extends Component {
	constructor(props) {
		super(props);

		this.menuButtonClickHandler = this.menuButtonClickHandler.bind(this);

		this.state = {
			searchParams: {}
		};
	}

	componentDidMount() {
		this.refs.router.history.listen(function() {
			this.setState({
				menuOpen: false
			});
		}.bind(this));

		document.addEventListener('mousedown', function(event) {
			if (!this.state.menuOpen) {
				return;
			}

			if (this.refs.menuButton.contains(event.target)) {
				event.stopPropagation();

				return;
			}

			if (this.refs.menuContent && !this.refs.menuContent.contains(event.target)) {
				this.setState({
					menuOpen: false
				})
			}
		}.bind(this));
	}

	menuButtonClickHandler(event) {
		event.stopPropagation();
		if (!this.state.menuOpen) {
			this.refs.menuContent.scrollTop = 0;
		}

		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}

	render() {
		return (
			<Router ref="router">
				<React.Fragment>
					<div className="header">

					    <div className="container">
							<h1 className="h2">
								<img src={logo} className="mr-2 mr-md-4" />
								<span><strong><abbr>{'ÍS'}</abbr></strong> Íslensk stafsetningarorðabók </span>
							</h1>

							<PageMenu className="hidden-mobile" />
						</div>

					</div>

					<StickyWatcher tagName="div"
						className="search-container"
						stickyClassName="sticky"
						onStick={function() {
							this.setState({
								searchFormSticky: true
							});
						}.bind(this)}
						onUnstick={function() {
							this.setState({
								searchFormSticky: false
							});
						}.bind(this)}
					>

					    <div className="container">

							<button className="btn-menu" ref="menuButton" onClick={this.menuButtonClickHandler}>
								<div className="hamburger-icon black">
									<div className="icon-middle" />
									<div className="icon-before-after" />
								</div>
							</button>

							<SearchBox searchString={this.state.searchParams.searchString} />
						</div>

					</StickyWatcher>

				    <div className={'container'+(this.state.searchFormSticky ? ' searchform-sticky' : '')}>
				    	<div className="row">

				    		<div className="col-12 col-sm-7 col-md-9">

				    			<Switch>

									<Route exact strict path="(/flettur/)?:searchString*(/sida/)?:page*" render={(routeProps) => (
										<SearchResultsList onSearch={searchParams => this.setState({searchParams: searchParams})} {...routeProps} />
									)} />
									<Route exact strict path="/fletta/:fletta_id" render={(routeProps) => (
										<FlettaView {...routeProps} />
									)} />

									<Route exact path="/*" render={(routeProps) => (
										<>
											<PageView />
										</>
									)} />

								</Switch>

				    		</div>

							<div className="col-12 col-sm-5 col-md-3 index-column">

				    			{
				    				/*
				    				Til baka tengill fyrir tvær rútur
				    				*/
				    			}

				    			<Switch>

									<Route exact strict path="/fletta/:fletta_id" render={(routeProps) => (
										<>
											<Link className="btn btn-info btn-block mb-2" to="/">&lt; Til baka á forsíðu</Link>
											<IndexList flid={routeProps.match.params.fletta_id} />
										</>
									)} />

									<Route exact strict path="(/flettur/)?:searchString*" render={(routeProps) => (
										<>
											<Link className="btn btn-info btn-block mb-2" to="/">&lt; Til baka á forsíðu</Link>
											<IndexList fletta={routeProps.match.params.searchString} />
										</>
									)} />

								</Switch>

				    		</div>

				    	</div>

					</div>

					<div className={'app-menu'+(this.state.menuOpen ? ' open' : '')} ref="menuContent">
						<div className="container">

							<button className="btn btn-link float-right" onClick={
								function() {
									this.setState({
										menuOpen: false
									});
								}.bind(this)}>
								<div className="hamburger-icon black open">
									<div className="icon-middle" />
									<div className="icon-before-after" />
								</div>
							</button>

							<h5 className="mb-4 pt-2">Stafsetningarorðabókin</h5>

							<div className="menu-links">

								<Link to="/">Forsíða</Link>

								<PageMenu />

							</div>

						</div>
					</div>

					<GoogleAnalytics />

					</React.Fragment>
			</Router>
		);
	}
}

export default App;
