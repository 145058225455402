import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

import config from './../config';

class BreadCrumbs extends Component {
	render() {
		return (
			<nav aria-label="breadcrumb">
				<ol className="breadcrumb">
					{
						this.props.path.map(function(item, index) {
							return <li key={index} className="breadcrumb-item" aria-current="page">
								{
									index == this.props.path.length-1 &&
									item.title
								}
								{
									index < this.props.path.length-1 &&
									<Link to={item.path}>{item.title}</Link>
								}
							</li>
						}.bind(this))
					}
				</ol>
			</nav>
		);
	}
}

export default BreadCrumbs;
