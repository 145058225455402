import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import _ from 'underscore';

import config from './../config';

class SearchResultsList extends Component {
	constructor(props) {
		super(props);

		this.url = config.apiRoot+'/api/flokkar/';

		window.malfarData = {};

		this.flokkarMenuItemClickHandler = this.flokkarMenuItemClickHandler.bind(this);

		this.state = {
			listData: [],
			openMenu: -1
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	flokkarMenuItemClickHandler(event) {
		this.setState({
			openMenu: event.target.dataset.index
		});
	}

	fetchData() {
		fetch(this.url)
			.then(function(response) {
				return response.json();
			})
			.then(function(json) {
				this.setState({
					listData: json.results
				});

				// Geymum upplýsingar um id og nöfn flokkar í window.malfarData.flokkar svo við getum náð í þær síðar (í SearchResultsList)
				let flokkar = [];
				let undirflokkar = [];

				_.each(json.results, function(flokkur) {
					flokkar.push({
						id: flokkur.id,
						nafn: flokkur.nafn
					});

					if (flokkur.undirflokkar) {
						_.each(flokkur.undirflokkar, function(undirflokkur) {
							undirflokkar.push({
								id: undirflokkur[0],
								nafn: undirflokkur[1]
							});
						});
					}
				})

				window.malfarData['flokkar'] = {
					nested: json.results,
					flokkar: flokkar,
					undirflokkar: undirflokkar
				};

			}.bind(this))
		;
	}

	render() {
		return (
			<ul className="menu-main nav nav-pills flex-column">
				<li className={'nav-item'}>
					<Link 
						onClick={function() {
							this.setState({
								openMenu: -1
							});
						}.bind(this)} 
						className="nav-link" 
						to={'/greinar/'}>Allar greinar</Link>
				</li>
				{
					this.state.listData.map(function(item, index) {
						return <li key={item.id} className={'nav-item'+(

								(
									this.state.openMenu == index ||
									(this.props.searchParams && this.props.searchParams.flokkur == item.id)
								) ? ' open' : ''

							)}>
							<Link data-index={index} 
								onClick={this.flokkarMenuItemClickHandler} 
								className="nav-link" 
								to={'/greinar//flokkur/'+item.id}>{item.nafn}</Link>
							<ul className={'menu-sub nav nav-pills flex-column'}>
								{
									item.undirflokkar.map(function(undirflokkur) {
										return <li key={item.id+'-'+undirflokkur[0]} 
											className={'nav-item'+(this.props.searchParams && this.props.searchParams.undirflokkur == undirflokkur[0] ? ' selected' : '')}>
											<Link className="nav-link" 
												to={'/greinar//flokkur/'+item.id+'/undirflokkur/'+undirflokkur[0]}>{undirflokkur[1]}</Link>
										</li>
									}.bind(this))
								}
							</ul>
						</li>;
					}.bind(this))
				}
			</ul>
		);
	}
}

export default withRouter(SearchResultsList);
